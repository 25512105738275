export const GET_MENU = 'GET_MENU'
export const CREATE_MENU = 'CREATE_MENU'
export const UPDATE_MENU = 'UPDATE_MENU'
export const GET_ALL_INGREDIENTS = 'GET_ALL_INGREDIENTS'
export const ERROR = 'ERROR'
export const FILTER = 'FILTER'
export const GET_INGREDIENT_ID = 'GET_INGREDIENT_ID'
export const LOGIN_STATUS = 'LOGIN_STATUS'
export const CREATE_INGREDIENTS = 'CREATE_INGREDIENTS'
export const GET_ROLES = 'GET_ROLES'
export const GET_RECETA = 'GET_RECETA'
export const ORDER_BY_PRICE = "ORDER_BY_PRICE";
export const FILTER_BY_TAG = "FILTER_BY_TAG";
export const ORDER_BY_RECOMMENDATION = "ORDER_BY_RECOMMENDATION"
export const SORT_BY_ACTIVITY = "SORT_BY_ACTIVITY" 
export const ORDER_BY_QUANTITY = "ORDER_BY_QUANTITY"
export const GET_ORDERS = "GET_ORDERS"
export const DELIVERY = "DELIVERY"
export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const DELETE_RECIPE = "DELETE_RECIPE"
export const GET_BALANCE = "GET_BALANCE"
export const DELETE_MENU = "DELETE_MENU"
export const GET_TAGS = "GET_TAGS"
